import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
} from '@mui/material';
import Navbar_public from './Navbar_public';

const Donation = () => {
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    amount: '',
  });
  
  const [amountFocused, setAmountFocused] = useState(false); // State to track focus

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement payment processing logic here
    console.log('Payment Information:', formData);
  };

  return (
    <div>
      <Navbar_public />      
      <Box
        sx={{
          backgroundImage: 'url(images/background6.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
        }}
      >
        <Box sx={{ backgroundColor: 'rgba(238, 238, 238, 0.37)', padding: 3, borderRadius: 2, width:'70%' }}>
          <Typography variant="h4" gutterBottom>
            Love Offering
          </Typography>
          <Typography variant="body1" paragraph>
            Behind 4EVR.ORG.ZA is a team of dedicated developers and infrastructure. Your gift, no matter how small, will help keep your loved one's memory alive and s/he will feel your love-offering in the after-life.
          </Typography>
          
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name on Card"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Card Number"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Expiration Date (MM/YY)"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="CVV"
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="password"
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Donation Amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="number"
                  onFocus={() => setAmountFocused(true)} // Set focus on input
                  onBlur={() => {
                    if (formData.amount === '') setAmountFocused(false); // Reset focus if empty
                  }}
                  InputProps={{
                    startAdornment: amountFocused || formData.amount ? <span>$</span> : null, // Show dollar sign if focused or amount is not empty
                  }}
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
              Submit Offering ❤️💸
            </Button>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Donation;

import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import {
    Button,
    Card,
    CardContent,
    Typography,
    TextField,
    Box,
    IconButton,
    Modal,
    Avatar,
} from '@mui/material';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


function Edit_messages() {
    const [messages, setMessages] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [newReply, setNewReply] = useState('');
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [replyPath, setReplyPath] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(''); // 'comment', 'reply', or 'edit'
    const [tempMessage, setTempMessage] = useState(''); // For editing message text
    const [tempReplyPath, setTempReplyPath] = useState(null);
    const [expandedMessages, setExpandedMessages] = useState({});

    useEffect(() => {
        const fetchMessages = async () => {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');

            if (userId) {
                const { data: userData, error: userError } = await supabase
                    .from('Users')
                    .select('name, surname')
                    .eq('id', userId);

                if (userError) {
                    console.error(userError);
                    return;
                }

                setUserName(userData[0]?.name || '');
                setUserSurname(userData[0]?.surname || '');
            }

            const { data: messageData, error: messageError } = await supabase
                .from('memorials')
                .select('messages')
                .eq('memorial_Id', memorialId);

            if (messageError) {
                console.error(messageError);
                return;
            }

            setMessages(messageData[0]?.messages || []);
        };

        fetchMessages();
    }, []);

    const handleTextFieldClick = () => {
        setModalAction('comment');
        setIsModalOpen(true);
    };

    const toggleReadMore = (index) => {
        setExpandedMessages((prevExpanded) => ({
            ...prevExpanded,
            [index]: !prevExpanded[index],
        }));
    };

    const truncateText = (text, isExpanded) => {
        if (text.length <= 200 || isExpanded) {
            return text;
        }
        return text.slice(0, 200) + '...';
    };

    const handlePostMessage = async () => {
        setModalAction('comment');
        setIsModalOpen(true);
    };

    const postMessage = async (name, surname, messageText) => {
        const newMessageObj = {
            name,
            surname,
            text: messageText,
            likes: 0,
            dislikes: 0,
            replies: [],
            timestamp: new Date().toISOString(),
        };

        const updatedMessages = [...messages, newMessageObj];

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
            return;
        }

        setMessages(updatedMessages);
    };

    const handleLike = async (index, replyPath = []) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, index, replyPath);
        target.likes += 1;
        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const handleDislike = async (index, replyPath = []) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, index, replyPath);
        target.dislikes += 1;
        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const handleReply = (index) => {
        setModalAction('reply');
        setTempReplyPath([index]);
        setIsModalOpen(true);
    };

    const handlePostReply = async (name, surname, replyText) => {
        if (!tempReplyPath) return;

        await postReply(name, surname, replyText, tempReplyPath);
        setNewReply('');
        setTempReplyPath(null);
    };

    const postReply = async (name, surname, replyText, path) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, path[0], path.slice(1));
        if (!target.replies) target.replies = [];
        target.replies.push({
            name,
            surname,
            text: replyText,
            likes: 0,
            dislikes: 0,
            replies: [],
            timestamp: new Date().toISOString(),
        });

        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const getNestedMessage = (messages, index, replyPath) => {
        let target = messages[index];
        for (let i of replyPath) {
            if (!target.replies) target.replies = [];
            target = target.replies[i];
        }
        return target;
    };

    const editMessage = (index) => {
        const messageToEdit = messages[index];
        setModalAction('edit');
        setTempMessage(messageToEdit.text);
        setTempReplyPath([index]);
        setIsModalOpen(true);
    };

    const editReply = (index, replyPath) => {
        const replyToEdit = getNestedMessage(messages, index, replyPath);
        setModalAction('edit');
        setTempMessage(replyToEdit.text);
        setTempReplyPath(replyPath);
        setIsModalOpen(true);
    };

    const handleModalSubmit = async (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const surname = e.target.surname.value;
        const messageText = e.target.messageText.value;

        if (modalAction === 'comment') {
            await postMessage(name, surname, messageText);
            setNewMessage('');
        } else if (modalAction === 'reply') {
            await handlePostReply(name, surname, messageText);
        } else if (modalAction === 'edit') {
            await handleEditMessage(name, surname, messageText);
        }

        setIsModalOpen(false);
    };

    const handleEditMessage = async (name, surname, messageText) => {
        const updatedMessages = [...messages];
        if (tempReplyPath.length === 1) {
            updatedMessages[tempReplyPath[0]].name = name;
            updatedMessages[tempReplyPath[0]].surname = surname;
            updatedMessages[tempReplyPath[0]].text = messageText;
        } else {
            const target = getNestedMessage(updatedMessages, tempReplyPath[0], tempReplyPath.slice(1));
            target.name = name;
            target.surname = surname;
            target.text = messageText;
        }

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
            return;
        }

        setMessages(updatedMessages);
    };

    const modalBody = (
        <Box
            component="form"
            onSubmit={handleModalSubmit}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                padding: 4,
                borderRadius: 2,
                boxShadow: 24,
                width: 400,
            }}
        >
            <Typography variant="h6" component="h2">
                {modalAction === 'edit' ? 'Edit Message' : modalAction === 'reply' ? 'Reply to Message' : 'New Message'}
            </Typography>
            <TextField
                name="name"
                label="Name"
                value={userName}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="surname"
                label="Surname"
                value={userSurname}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="messageText"
                label="Message"
                multiline
                rows={4}
                value={modalAction === 'edit' ? tempMessage : newMessage}
                onChange={(e) => modalAction === 'edit' ? setTempMessage(e.target.value) : setNewMessage(e.target.value)}
                fullWidth
                margin="normal"
                required
            />
            <Button type="submit" variant="contained">
                {modalAction === 'edit' ? 'Update' : 'Submit'}
            </Button>
        </Box>
    );

    return (
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', padding: '10px' }}>
            <Typography variant="h6" align="left">
                {messages ? messages.length : 0} Tributes
            </Typography>
            <TextField
                value={newMessage}
                onClick={handleTextFieldClick}
                placeholder="Add a comment"
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            {messages && messages.map((message, index) => (
                <Card key={index} sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <Avatar alt={message.name} src="/images/profile.svg" />
                            <Typography variant="h6" align="left">
                                {message.name} {message.surname}
                            </Typography>
                        </Box>
                        <Typography variant="body1" align="left">
                            {truncateText(message.text, expandedMessages[index])}
                            {message.text.length > 200 && (
                                <Button onClick={() => toggleReadMore(index)}>
                                    {expandedMessages[index] ? 'Read Less' : 'Read More'}
                                </Button>
                            )}
                        </Typography>

                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => handleLike(index)}>
                                <img src="/images/red_rose_icon.png" alt="like" width="24" />
                            </IconButton>
                            <Typography>{message.likes}</Typography>
                            <IconButton onClick={() => handleDislike(index)}>
                                <img src="/images/orange_rose_icon.png" alt="dislike" width="24" />
                            </IconButton>
                            <Typography>{message.dislikes}</Typography>
                            <Button onClick={() => editMessage(index)}>
                                <img src='/images/edit.png' width={24} alt="edit" style={{ verticalAlign: 'middle', marginRight: 4 }} /> Edit
                            </Button>
                            <Button onClick={() => handleReply(index)}>Reply</Button>
                        </Box>
                        {message.replies && message.replies.map((reply, replyIndex) => (
                            <Box key={replyIndex} sx={{ marginLeft: 4, textAlign: 'left' }}>
                                <Box display="flex" alignItems="center">
                                    <Avatar alt={reply.name} src="/images/profile.svg" />
                                    <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
                                        {reply.name} {reply.surname}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" align="left">
                                    {reply.text}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <IconButton onClick={() => handleLike(index, [replyIndex])}>
                                        <img src="/images/red_rose_icon.png" alt="like" width="24" />
                                    </IconButton>
                                    <Typography>{reply.likes}</Typography>
                                    <IconButton onClick={() => handleDislike(index, [replyIndex])}>
                                        <img src="/images/orange_rose_icon.png" alt="dislike" width="24" />
                                    </IconButton>
                                    <Typography>{reply.dislikes}</Typography>
                                    <Button onClick={() => editReply(index, [replyIndex])}>
                                        <img src='/images/edit.png' width={24} alt="edit" style={{ verticalAlign: 'middle', marginRight: 4 }} /> Edit
                                    </Button>
                                    <Button onClick={() => handleReply(index)}>
                                        Reply
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </CardContent>
                </Card>
            ))}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {modalBody}
            </Modal>
        </div>
    );
}

export default Edit_messages;

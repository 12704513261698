import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';

const useStyles = makeStyles((theme) => ({
  hero: {
    minHeight: '100vh', // Ensure the hero section covers at least the full viewport height
    backgroundImage: 'url(/images/hero.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    margin: 0, // Ensure no margins are applied
    padding: 0, // Ensure no padding is applied
    position: 'relative',
    overflow: 'hidden',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10vh', // Add padding on mobile devices
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '8vh', // Add padding on tablet devices
    },
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1,
  },
  heroTitle: {
    fontSize: '6rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  heroSubtitleWrapper: {
    position: 'relative',
    zIndex: 2,
    width: '90%',
    maxWidth: '800px',
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    maxWidth: '100%', // Ensure subtitle takes full width of the wrapper
    width: '100%', // Ensure subtitle takes full width of the wrapper
    position: 'relative',
    opacity: 0,
    transition: 'opacity 1s ease-in-out',
    display: 'inline-block', // Ensure subtitle respects width settings
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subtitleVisible: {
    opacity: 1,
  },
}));

function Home() {
  const classes = useStyles();
  const [showFirstSubtitle, setShowFirstSubtitle] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      const intervalId = setInterval(() => {
        setShowFirstSubtitle(prev => !prev);
      }, 7000); // Change every 7 seconds (2 seconds delay + 5 seconds transition)

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, 1000); // Initial delay of 1 second before starting the switch

    return () => clearTimeout(timer); // Cleanup initial timer on component unmount
  }, []);

  return (
    <div style={{ margin: 0, padding: 0, height: '100%' }}> {/* Ensure no extra spacing */}
      <Navbar2 />
      <Box id="hero" className={classes.hero}>
        <div className={classes.heroOverlay} />
        <Typography variant="h1" className={classes.heroTitle}>
          Welcome to 4EVR
        </Typography>
        <div className={classes.heroSubtitleWrapper}>
          <Typography
            variant="h6"
            className={`${classes.heroSubtitle} ${showFirstSubtitle ? classes.subtitleVisible : ''}`}
          >
            Where the whole world finally comes to rest
          </Typography>
          <Typography
            variant="h6"
            className={`${classes.heroSubtitle} ${!showFirstSubtitle ? classes.subtitleVisible : ''}`}
          >
            We are sorry for your deep loss. 4EVR helps you immortalize your loved ones with beautiful resting places and music, and by preserving photographs, videos, and voice clips of them. You can share your memorial with close family and friends and ask them to leave a tribute.
          </Typography>
        </div>
      </Box>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

async function fetchUserName(userId) {
    let { data, error } = await supabase
        .from('Users')
        .select('name, surname')
        .eq('id', userId)
        .single();

    if (error) {
        console.error(error);
        return null;
    }

    return `${data.name} ${data.surname}`;
}

const DeleteModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
}));

const DataGridContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    overflowX: 'auto',
    padding: theme.breakpoints.down('sm') ? '10px' : '20px',
    '& .MuiDataGrid-root': {
        minWidth: 1000,
    },
}));

function My_memorials() {
    const [memorials, setMemorials] = useState([]);
    const userId = Cookies.get('userId');
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentMemorial, setCurrentMemorial] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);

    useEffect(() => {
        fetchUserName(userId).then(setUserName);
    }, [userId]);

    useEffect(() => {
        const fetchMyMemorials = async () => {
            setIsLoading(true);
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('*')
                    .eq('owner_id', userId);

                if (error) {
                    throw error;
                }

                if (data) {
                    setMemorials(data.map((item, i) => ({
                        ...item,
                        id: i + 1,
                        created_on: new Date(item.created_at).toLocaleDateString(),
                        published: item.published ? 'Yes' : 'No'
                    })));
                }
            } catch (error) {
                console.error('Error fetching my memorials:', error.message);
            }
            setIsLoading(false);
        };

        if (userId) {
            fetchMyMemorials();
        }
    }, [userId]);

    const handleViewMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Memorial');
    };

    const handleEditMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Edit_memorial_info');
    };

    const handleTogglePublished = async (memorialId, currentValue) => {
        try {
            const { data, error } = await supabase
                .from('memorials')
                .update({ published: !currentValue })
                .eq('memorial_Id', memorialId);

            if (error) {
                throw error;
            }

            setMemorials(prevMemorials => prevMemorials.map(memorial => {
                if (memorial.memorial_Id === memorialId) {
                    return { ...memorial, published: !currentValue ? 'Yes' : 'No' };
                }
                return memorial;
            }));
        } catch (error) {
            console.error('Error updating published status:', error.message);
        }
    };

    const handleDeleteMemorial = (memorialId) => {
        setDeleteCandidate(memorialId);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteCandidate(null);
        setDeleteModalOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const { data, error } = await supabase
                .from('memorials')
                .delete()
                .eq('memorial_Id', deleteCandidate);

            if (error) {
                throw error;
            }

            setMemorials(prevMemorials => prevMemorials.filter(memorial => memorial.memorial_Id !== deleteCandidate));
            setDeleteModalOpen(false);
            console.log('Memorial deleted successfully');
        } catch (error) {
            console.error('Error deleting memorial:', error.message);
        }
    };

    // Define columns for DataGrid
    const columns = [
        { field: 'created_on', headerName: 'Created On', width: 150 },
        { field: 'deceased_name', headerName: 'First Name', width: 150 },
        { field: 'deceased_surname', headerName: 'Last Name', width: 150 },
        { field: 'deceased_date_of_birth', headerName: 'Date of Birth', width: 150 },
        { field: 'deceased_date_of_death', headerName: 'Date of Death', width: 150 },
        {
            field: 'published',
            headerName: 'Published',
            width: 120,
            renderCell: (params) => (
                <Switch
                    checked={params.row.published === 'Yes'}
                    onChange={() => handleTogglePublished(params.row.memorial_Id, params.row.published === 'Yes')}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEditMemorial(params.row.memorial_Id)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleViewMemorial(params.row.memorial_Id)}
                    >
                        View
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleDeleteMemorial(params.row.memorial_Id)}
                    >
                        Delete
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background1.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '85%', margin: '50px', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                    <Typography variant="h4" component="h4" gutterBottom style={{ marginBottom: '20px' }}>
                        {userName}'s Memorials
                    </Typography>
                    <DataGridContainer>
                        <DataGrid
                            rows={memorials}
                            columns={columns}
                            pageSize={5}
                            loading={isLoading}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </DataGridContainer>
                </div>
            </div>
            <DeleteModal
                open={deleteModalOpen}
                onClose={handleCloseDeleteModal}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box sx={{
                    width: '75%',
                    maxHeight: '90%',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    p: 2,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Typography variant="h6" align="center" id="delete-modal-title">Confirm Deletion</Typography>
                    <Typography variant="body1" align="center" id="delete-modal-description">Are you sure you want to delete this memorial?</Typography>
                    <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                        <Button variant="contained" color="error" onClick={handleConfirmDelete}>Delete</Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseDeleteModal}>Cancel</Button>
                    </Box>
                </Box>
            </DeleteModal>
        </div>
    );
}

export default My_memorials;

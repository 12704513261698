import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';

// Supabase credentials
const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const Rose = () => {
    const [buttonLabel, setButtonLabel] = useState('Add A Rose');
    const [roseAdded, setRoseAdded] = useState(false);
    const [hovered, setHovered] = useState(false); // Hover state
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const RoseIcon = '/images/rose.png'; // Path to rose icon image

    useEffect(() => {
        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (memorialId && userId) {
            const fetchMemorialData = async () => {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('roses')
                    .eq('memorial_Id', memorialId)
                    .single();

                if (error) {
                    console.error('Error fetching memorial data:', error);
                    return;
                }

                if (data && data.roses) {
                    const roses = data.roses;
                    const userRose = roses.find((rose) => rose.userId === userId);

                    if (userRose) {
                        setButtonLabel('Rose Added');
                        setRoseAdded(true); // Set the state to reflect that the rose was added
                    }
                }
            };

            fetchMemorialData();
        }
    }, []);

    const handleAddRose = async () => {
        if (roseAdded) return; // Do nothing if a rose has already been added

        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (!userId) {
            setOpen(true); // Open the modal if no userId cookie is found
            return;
        }

        const { data: userData, error: userError } = await supabase
            .from('Users')
            .select('name, surname, profile_picture')
            .eq('id', userId)
            .single();

        if (userError) {
            console.error('Error fetching user data:', userError);
            return;
        }

        const { name, surname, profile_picture } = userData;

        const newRose = {
            userId,
            name,
            surname,
            profile_picture,
        };

        const { data, error } = await supabase
            .from('memorials')
            .select('roses')
            .eq('memorial_Id', memorialId)
            .single();

        if (error) {
            console.error('Error fetching memorial data:', error);
            return;
        }

        let updatedRoses = data.roses ? [...data.roses, newRose] : [newRose];

        const { error: updateError } = await supabase
            .from('memorials')
            .update({ roses: updatedRoses })
            .eq('memorial_Id', memorialId);

        if (updateError) {
            console.error('Error updating roses:', updateError);
        } else {
            setButtonLabel('Rose Added');
            setRoseAdded(true); // Mark that a rose has been added
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
    
                <Button
                    variant="contained"
                    onClick={handleAddRose}
                    onMouseEnter={() => setHovered(true)} // Expand on hover
                    onMouseLeave={() => setHovered(false)} // Shrink when not hovering
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        width: hovered ? '50%' : '50px', // Square when not hovered, expands on hover
                        height: '50px', // Fixed height to keep it square
                        transition: 'width 0.3s ease', // Smooth width transition
                        overflow: 'hidden', // Hide text when button is square
                        display: 'flex', // Align icon and text
                        alignItems: 'center', // Center content vertically
                        justifyContent: hovered ? 'space-between' : 'center', // Center icon when square, space-between when expanded
                        padding: hovered ? '0 16px' : '0', // Add padding when expanded
                        cursor: roseAdded ? 'default' : 'pointer', // Show pointer only if clickable
                    }}
                >
                    {/* Icon */}
                    <img src={RoseIcon} alt="Rose Icon" style={{ width: '24px', height: '24px' }} />

                    {/* Show text only on hover, even if rose has been added */}
                    {hovered && (
                        <Box ml={1}>{buttonLabel}</Box>
                    )}
                </Button>
            

            {/* Dialog for authentication */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Authentication Required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You must be logged in to interact with the memorial. Please log in or register.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate('/authentication')} color="primary">
                        Login/Register
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Rose;
